import React from "react";
import LayoutWrapper from "../component/LayoutWrapper/Index";
import BlogsPosts from "../component/Blog/BlogPost/index";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import ShareLinks from "../component/Blog/ShareLinks";
import AuthorAvatar from "../component/Blog/AuthorAvatar";
import CommonParagraph from "../component/Blog/CommonParagraph";
import Heading from "../component/Blog/Heading";
import BlogLink from "../component/Blog/BlogLink";
import { blogList } from "../data/blogs";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import ContactSlideoutLink from "../component/Blog/ContactSlideoutLink";

const BlogPost = () => {
  const blogData = blogList.filter(
    (blog) => blog.url == "/welcome-to-city-heights-san-diego-s-hidden-gem"
  );

  return (
    <LayoutWrapper
      title={blogData[0].title}
      desc={blogData[0].metaDescription}
      headerLogo="../../images/Selby_logo_v2.svg"
      headerId="header_v2"
    >
      <div className="pt-[130px] pb-[80px] md:pt-[170px] md:pb-[70px] sm:pt-[30px] 2xs_extar:pb-[50px]">
        <div className="container">
          <div className="max-w-[800px] w-full mx-[auto]">
            <h1 className="text-d-3xl text-[#020101] font-IbarraRealNova font-[600] mb-[32px] sm:text-d-xl sm:mb-[]">
              {blogData[0].title}
            </h1>
          </div>
          <div className="flex justify-between items-center max-w-[800px] w-full mx-[auto] mb-[40px] 2xs_extar:flex-col  2xs_extar:justify-start 2xs_extar:items-start 2xs_extar:gap-[20px]">
            <div className="flex gap-[14px]">
              <AuthorAvatar />
              <div className="">
                <p className="font-Quicksand text-[#1A1A1A] text-d-2lg font-[600] mb-[4px]">
                  By {blogData[0].author}
                </p>
                <p className="font-Quicksand text-d-2lg font-[400] text-[#666666]">
                  {blogData[0].date}
                </p>
              </div>
            </div>
            <div className="flex gap-[24px]">
              <ShareLinks url={`https://selbysellssd.com${blogData[0].url}`} />
            </div>
          </div>

          <div className="">
            <StaticImage
              src="../images/The Selby Team_Welcome to City Heights_ San Diego’s Hidden Gem.jpg"
              alt="blogpost"
            />
            <div className="max-w-[800px] w-full mx-[auto] py-[80px] 2xs_extar:py-[30px]">
              <CommonParagraph>
                City Heights is an under-the-radar San Diego community that might not make it onto
                most visitors' itineraries. However, many locals are looking to this hidden gem for
                its convenient location, relatively affordable housing, and diverse culture and
                cuisine. Here are a few reasons to fall in love with City Heights.
              </CommonParagraph>
              <Heading type="h2">Affordable Housing</Heading>
              <CommonParagraph>
                City Heights offers an appealing alternative in a city where the median home price
                hovers around $1 million. We might even go so far as to say City Heights homes are
                priced to sell—and quickly.
              </CommonParagraph>
              <CommonParagraph>
                The median listing price is currently $686,500, up 22.4% from last year. With over
                44% of homes being sold above the asking price and within 14 days of listing, it’s
                safe to say the City Heights market is healthy and relatively competitive.
              </CommonParagraph>
              <Heading type="h2">Convenient Location</Heading>
              <CommonParagraph>
                One of the city’s biggest draws is its prime location. Located only 10-15 minutes
                from downtown San Diego, the airport, the San Diego Zoo, Mission and Pacific
                Beaches, and Balboa Park, City Heights offers easy access to the city’s top
                attractions. With major freeways like I-15, I-805, and SR 94, getting around is a
                breeze.
              </CommonParagraph>
              <Heading type="h2">Cultural Diversity</Heading>
              <CommonParagraph>
                With a diversity score of 74 out of 100, it’s no exaggeration that City Heights is a
                true melting pot. The neighborhood’s population is 61% Hispanic, 15.4% White, and
                11% Asian. This diversity can be seen in the community’s food scene, where you’ll
                find authentic dishes from all over the world (more on this below!)
              </CommonParagraph>
              <Heading type="h2">Awesome Food</Heading>
              <CommonParagraph>
                Are you looking to quench your thirst or quiet your grumbling tummy? City Heights is
                your spot. Not only is it one of the most unassuming and unpretentious places to
                eat, but the choices are as diverse as the population. Whether you crave Chinese,
                Cambodian, Nigerian, Italian, Mexican, Vietnamese, Filipino, or American soul food,
                you can find it here.
              </CommonParagraph>
              <CommonParagraph>Some of our local favorites include:</CommonParagraph>
              <CommonParagraph>
                <ul className="text-d-5lg text-[#666666] font-Quicksand font-[500] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg list-disc list-inside mb-[30px]">
                  <li>Super Cocina for authentic, home-style Mexican</li>
                  <li>Fredcel Lumpias for Filipinos (cash only!)</li>
                  <li>Minh Ky for traditional and very tasty Chinese</li>
                  <li>Red Sea for no-frills yet unbelievably delicious Ethiopian</li>
                </ul>
              </CommonParagraph>
              <Heading type="h2">Thriving Arts Scene</Heading>
              <CommonParagraph>
                City Heights is also a neighborhood that values creativity and community. The area
                is home to over 30 stunning murals, each telling a unique story about the people and
                history of the community.
              </CommonParagraph>
              <CommonParagraph>
                The latest addition to this colorful collection is a series of murals on the
                exterior of City Heights Plaza del Sol, designed by artist Baltézar and painted by
                over 100 community members. These murals, commissioned by local non-profit ArtReach,
                serve as a tribute to the neighborhood’s diversity and spirit.
              </CommonParagraph>
              <Heading type="h2">Community Activism</Heading>
              <CommonParagraph>
                City Heights residents are not afraid to tell the local government what they want.
                It's always been like that, but this activistic spirit took off in the 1980s when a
                few community members banded together and formed the{" "}
                <BlogLink
                  url="https://www.cityheightscdc.org/"
                  text="City Heights Community
                Development Corporation"
                />
                . Over the last four decades, they pushed back when city officials wanted to cut up
                the community with the I-15 freeway, which worked. They've fought against blight and
                crime, planted gardens, welcomed refugees, and implemented job training programs.
              </CommonParagraph>
              <CommonParagraph>
                This is the spirit of City Heights, and it is something to be proud of.
              </CommonParagraph>
              <CommonParagraph>
                Thinking about buying in City Heights? Then you will need a trusted ally by your
                side. <BlogLink url="https://selbysellssd.com/" text="The Selby Team" /> understands
                the local market and can give you the insight and guidance you need to stay
                competitive while making informed decisions that fit your lifestyle and goals.
                <ContactSlideoutLink text="Contact us now!" />
              </CommonParagraph>
            </div>
            <div className="">
              <div className="flex justify-center gap-[24px]">
                <ShareLinks url={`https://selbysellssd.com${blogData[0].url}`} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </LayoutWrapper>
  );
};

export default BlogPost;
